import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`"lol you can become a famous artist by just painting colorful squares"`}</p>
    </blockquote>
    <p>{`Yeah turns out generative art is really hard. And Mondrian did it manually.`}</p>
    <p><a parentName="p" {...{
        "href": "https://mondrian-generator.swizec-react-dataviz.now.sh/"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/9czz3FL.gif",
          "alt": null
        }}></img></a></p>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Piet_Mondrian"
      }}>{`Piet Mondrian`}</a>{` was a Dutch
painter famous for his style of grids with basic squares and black lines. So
famous in fact, Google finds him as
`}<a parentName="p" {...{
        "href": "https://www.google.com/search?q=squares+art+guy&oq=squares+art+guy&aqs=chrome..69i57.3689j0j1&sourceid=chrome&ie=UTF-8"
      }}>{`"squares art guy"`}</a>{`.`}</p>
    <p>{`The signature style grew out of his earlier cubist works seeking a universal
beauty understood by a all humans.`}</p>
    <blockquote>
      <p parentName="blockquote">{`I believe it is possible that, through horizontal and vertical lines
constructed with awareness, but not with calculation, led by high intuition,
and brought to harmony and rhythm, these basic forms of beauty, supplemented
if necessary by other direct lines or curves, can become a work of art, as
strong as it is true.`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/commons/8/80/Piet_Mondrian%2C_1911%2C_Gray_Tree_%28De_grijze_boom%29%2C_oil_on_canvas%2C_79.7_x_109.1_cm%2C_Gemeentemuseum_Den_Haag%2C_Netherlands.jpg",
        "alt": "An early cubist work by Piet Mondrian"
      }}></img></p>
    <p>{`So I figured what better way to experiment with
`}<a parentName="p" {...{
        "href": "https://observablehq.com/@d3/treemap"
      }}>{`D3 treemaps`}</a>{` than to pay an homage to
this great artist.`}</p>
    <p>{`You can watch the full live stream here:`}</p>
    <lite-youtube {...{
      "videoid": "6Ad0I8RZhY8",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`GitHub link here 👉
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/mondrian-generator"
      }}>{`Swizec/mondrian-generator`}</a></p>
    <p>{`And try it out
`}<a parentName="p" {...{
        "href": "https://mondrian-generator.swizec-react-dataviz.now.sh/"
      }}>{`in your browser`}</a></p>
    <p>{`It's not as good as Mondrian originals, but we learned a lot 👩‍🎨`}</p>
    <p>{`You can read this article online at
`}<a parentName="p" {...{
        "href": "https://reactfordataviz.com/articles/mondrian-art-generator/"
      }}>{`reactfordataviz.com/articles/mondrian-art-generator/`}</a></p>
    <h2 {...{
      "id": "what-is-a-treemap-anyway"
    }}>{`What is a treemap anyway?`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.npmjs.com/package/d3-hierarchy"
      }}><img parentName="a" {...{
          "src": "https://raw.githubusercontent.com/d3/d3-hierarchy/master/img/treemap.png",
          "alt": "A treemap built with D3"
        }}></img></a></p>
    <blockquote>
      <p parentName="blockquote">{`Introduced by Ben Shneiderman in 1991, a treemap recursively subdivides area
into rectangles according to each node’s associated value.`}</p>
    </blockquote>
    <p>{`In other words, a treemap takes a rectangle and packs it with smaller
rectangles based on a tiling algorithm. Each rectangle's area is proportional
to the value it represents.`}</p>
    <p>{`Treemaps are most often used for presenting budgets and other relative sizes.
Like in this interactive
`}<a parentName="p" {...{
        "href": "https://obamawhitehouse.archives.gov/interactive-budget"
      }}>{`example of a government budget from 2016`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/YzOsWfp.png",
        "alt": null
      }}></img></p>
    <p>{`You can see at a glance most of the money goes to social security, then health
care, which is split between medicaid, children's health, etc.`}</p>
    <p>{`Treemaps are great for recursive data like that.`}</p>
    <h2 {...{
      "id": "using-a-d3-treemap-to-generate-art-with-react"
    }}>{`Using a D3 treemap to generate art with React`}</h2>
    <p>{`We wanted to play with treemaps per a reader's request, but couldn't find an
interesting dataset to visualize. `}<em parentName="p">{`Generating`}</em>{` data was the solution.
Parametrizing it with sliders, pure icing on the cake.`}</p>
    <p>{`Also I was curious how close we can get :)`}</p>
    <p>{`3 pieces have to work together to produce an interactive piece of art:`}</p>
    <ol>
      <li parentName="ol">{`A recursive rendering component`}</li>
      <li parentName="ol">{`A function that generates treemappable data`}</li>
      <li parentName="ol">{`Sliders that control inputs to the function`}</li>
    </ol>
    <h3 {...{
      "id": "a-recursive-rendering-component"
    }}>{`A recursive rendering component`}</h3>
    <p>{`Treemaps are recursive square subdivisions. They come with a bunch of tiling
algorithms, the most visually stunning of which is the
`}<a parentName="p" {...{
        "href": "https://www.win.tue.nl/~vanwijk/stm.pdf"
      }}>{`squarified treemaps`}</a>{` algorithm
described in 2000 by Dutch researchers.`}</p>
    <p>{`What is it with Dutch people and neat squares 🤔`}</p>
    <p>{`While beautiful, the squarified treemaps algorithm did not look like a
Mondrian.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/sw6pO2r.png",
        "alt": "Squarified treemap of our Mondrian function"
      }}></img></p>
    <p>{`Subtle difference, I know, but squarified treemaps are based on the
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Golden_ratio"
      }}>{`golden ratio`}</a>{` and Piet Mondrian's
art does not look like that. We used the `}<inlineCode parentName="p">{`d3.treemapBinary`}</inlineCode>{` algorithm instead.
It aims to create a balanced binary tree.`}</p>
    <h4 {...{
      "id": "main-mondrian-component"
    }}>{`main `}<inlineCode parentName="h4">{`<Mondrian>`}</inlineCode>{` component`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/Mondrian.js

const Mondrian = ({ x, y, width, height, data }) => {
  const treemap = d3
    .treemap()
    .size([width, height])
    .padding(5)
    .tile(d3.treemapBinary);

  const root = treemap(
    hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => 0.5 - Math.random())
  );

  return (
    <g transform={\`translate(\${x}, \${y})\`}>
      <MondrianRectangle node={root} />
    </g>
  );
};
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`<Mondrian>`}</inlineCode>{` component takes some props and instantiates a new treemap
generator. We could've wrapped this in a `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` call for better performance,
but it seemed fast enough.`}</p>
    <p>{`We set the treemap's `}<inlineCode parentName="p">{`size()`}</inlineCode>{` from width and height props, a guessed
`}<inlineCode parentName="p">{`padding()`}</inlineCode>{` of 5 pixels, and a tiling algorithm.`}</p>
    <p>{`This creates a `}<inlineCode parentName="p">{`treemap()`}</inlineCode>{` generator – a method that takes data and returns
that same data transformed with values used for rendering.`}</p>
    <p>{`The generator takes a `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-hierarchy"
      }}>{`d3-hierarchy`}</a>{`, which
is a particular data format shared by all hierarchical rendering generators in
the D3 suite. Rather than build it ourselves, we feed our source data into the
`}<inlineCode parentName="p">{`hierarchy()`}</inlineCode>{` method. That cleans it up for us ✌️`}</p>
    <p>{`We need the `}<inlineCode parentName="p">{`sum()`}</inlineCode>{` method to tell the hierarchy how to sum up the values of
our squares, and we use random sorting because that produced nicer results.`}</p>
    <h4 {...{
      "id": "a-mondrianrectangle-component-for-each-square"
    }}>{`a `}<inlineCode parentName="h4">{`<MondrianRectangle>`}</inlineCode>{` component for each square`}</h4>
    <p>{`We render each level of the resulting treemap with a `}<inlineCode parentName="p">{`<MondrianRectangle>`}</inlineCode>{`
component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/Mondrian.js

const MondrianRectangle = ({ node }) => {
  const { x0, y0, x1, y1, children } = node,
    width = x1 - x0,
    height = y1 - y0;

  return (
    <>
      <rect
        x={x0}
        y={y0}
        width={width}
        height={height}
        style={
          fill: node.data.color,
          stroke: "black",
          strokeWidth: 5
        }
        onClick={() => alert(\`This node is \${node.data.color}\`)}
      />
      {children &&
        children.map((node, i) => <MondrianRectangle node={node} key={i} />)}
    </>
  );
};
`}</code></pre>
    <p>{`Each rectangle gets a `}<inlineCode parentName="p">{`node`}</inlineCode>{` prop with a bunch of useful properties.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`x0, y0`}</inlineCode>{` defines the top left corner`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`x1, y1`}</inlineCode>{` is the bottom right corner`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`children`}</inlineCode>{` are the child nodes from our hierarchy`}</li>
    </ul>
    <p>{`We render an SVG `}<inlineCode parentName="p">{`<rect>`}</inlineCode>{` component as the square representing `}<em parentName="p">{`this`}</em>{` node. Its
children we render by looping through the `}<inlineCode parentName="p">{`children`}</inlineCode>{` array and recursively
rendering a `}<inlineCode parentName="p">{`<MondrianRectangle>`}</inlineCode>{` component for each.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` is there just to show how you might make these interactive :)`}</p>
    <blockquote>
      <p parentName="blockquote">{`You can use this same principle to render any data with a treemap.`}</p>
    </blockquote>
    <h3 {...{
      "id": "a-mondrian-data-generator-method"
    }}>{`A Mondrian data generator method`}</h3>
    <p>{`We moved the generative art piece into a custom `}<inlineCode parentName="p">{`useMondrianGenerator`}</inlineCode>{` hook.
Keeps our code cleaner 😌`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

let mondrian = useMondrianGenerator({
  redRatio,
  yellowRatio,
  blueRatio,
  blackRatio,
  subdivisions,
  maxDepth,
});
`}</code></pre>
    <p>{`The method takes a bunch of arguments that act as weights on randomly generated
parameters. Ideally we'd create a stable method that always produces the same
result for the same inputs, but that proved difficult.`}</p>
    <p>{`As mentioned earlier, generative art is `}<em parentName="p">{`hard`}</em>{` so this method is gnarly. 😇`}</p>
    <h4 {...{
      "id": "weighed-random-color-generator"
    }}>{`Weighed random color generator`}</h4>
    <p>{`We start with a weighed random generator.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/useMondrianGenerator.js

// Create weighted probability distribution to pick a random color for a square
const createColor = ({ redRatio, blueRatio, yellowRatio, blackRatio }) => {
  const probabilitySpace = [
    ...new Array(redRatio * 10).fill('red'),
    ...new Array(blueRatio * 10).fill('blue'),
    ...new Array(yellowRatio * 10).fill('yellow'),
    ...new Array(blackRatio * 10).fill('black'),
    ...new Array(
      redRatio * 10 + blueRatio * 10 + yellowRatio * 10 + blackRatio * 10
    ).fill('#fffaf1'),
  ];

  return d3.shuffle(probabilitySpace)[0];
};
`}</code></pre>
    <p><inlineCode parentName="p">{`createColor`}</inlineCode>{` picks a color to use for each square. It takes desired ratios of
different colors and uses a trick I discovered in college. There are probably
better ways to create a [weighed random method, but this works well and is
something I can understand.`}</p>
    <p>{`You create an array with the amount of values proportional to the probabilities
you want. If you want `}<inlineCode parentName="p">{`red`}</inlineCode>{` to be twice as likely as `}<inlineCode parentName="p">{`blue`}</inlineCode>{`, you'd use an array
like `}<inlineCode parentName="p">{`[red, red, blue]`}</inlineCode>{`.`}</p>
    <p>{`Pick a random element from that array and you get values based on
probabilities.`}</p>
    <p>{`The result is a `}<inlineCode parentName="p">{`createColor`}</inlineCode>{` method that returns colors in the correct ratio
without knowing context of what's already been picked and what hasn't. ✌️`}</p>
    <h4 {...{
      "id": "generating-mondrians"
    }}>{`generating mondrians`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`useMondrianGenerator`}</inlineCode>{` hook itself is pretty long. I'll explain in code
comments so it's easier to follow along :)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/useMondrianGenerator.js

// Takes inputs and spits out mondrians
function useMondrianGenerator({
  redRatio,
  yellowRatio,
  blueRatio,
  blackRatio,
  subdivisions,
  maxDepth,
}) {
  // useMemo helps us avoid recalculating this all the time
  // saves computing resources and makes the art look more stable
  let mondrian = useMemo(() => {
    // calculation is wrapped in a method so we can use recursion
    // each level gets the current "value" that is evenly split amongst children
    // we use depth to decide when to stop
    const generateMondrian = ({ value, depth = 0 }) => {
      // each level gets a random number of children based on the subdivisions argument
      const N = Math.round(1 + Math.random() * (subdivisions * 10 - depth));

      // each node contains:
      // its value, used by treemaps for layouting
      // its color, used by <MondrianRectangle> for the color
      // its children, recursively generated based on the number of children
      return {
        value,
        color: createColor({
          redRatio,
          yellowRatio,
          blueRatio,
          blackRatio,
        }),
        children:
          // this check helps us stop when we need to
          // d3.range generates an empty array of length N that we map over to create children
          depth < maxDepth * 5
            ? d3.range(N).map(_ =>
                generateMondrian({
                  value: value / N,
                  depth: depth + 1,
                })
              )
            : null,
      };
    };

    // kick off the recursive process with a value of 100
    return generateMondrian({
      value: 100,
    });
    // regenerate the base data when max depth or rate of subdivisions change
  }, [maxDepth, subdivisions]);

  // Iterate through all children and update colors when called
  const updateColors = node => ({
    ...node,
    color: createColor({
      redRatio,
      yellowRatio,
      blueRatio,
      blackRatio,
    }),
    children: node.children ? node.children.map(updateColors) : null,
  });

  // useMemo again helps with stability
  // We update colors in our dataset whenever those ratios change
  // depending on subdivisions and maxDepth allows the data update from that earlier useMemo to propagate
  mondrian = useMemo(() => updateColors(mondrian), [
    redRatio,
    yellowRatio,
    blueRatio,
    blackRatio,
    subdivisions,
    maxDepth,
  ]);

  return mondrian;
}
`}</code></pre>
    <p>{`And that creates mondrian datasets based on inputs. Now we just need the
inputs.`}</p>
    <h3 {...{
      "id": "sliders-for-function-inputs"
    }}>{`Sliders for function inputs`}</h3>
    <p>{`Thanks to React Hooks, our sliders were pretty easy to implement. Each controls
a ratio for a certain value fed into a random data generation method.`}</p>
    <p>{`Take the slider that controls the ratio of red squares for example.`}</p>
    <p>{`It starts life as a piece of state in the `}<inlineCode parentName="p">{`<App>`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

const [redRatio, setRedRatio] = useState(0.2);
`}</code></pre>
    <p><inlineCode parentName="p">{`redRatio`}</inlineCode>{` is the value, `}<inlineCode parentName="p">{`setRedRatio`}</inlineCode>{` is the value setter, `}<inlineCode parentName="p">{`0.2`}</inlineCode>{` is the
initial value.`}</p>
    <p>{`Render the slider as a `}<inlineCode parentName="p">{`<Range>`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

<Range name="red" value={redRatio} onChange={setRedRatio} />
`}</code></pre>
    <p>{`Value comes from our state, update state on change.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`<Range>`}</inlineCode>{` component itself looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

const Range = ({ name, value, onChange }) => {
  return (
    <div style={ display: "inline-block" }>
      {name}
      <br />
      <input
        type="range"
        name={name}
        min={0}
        max={1}
        step={0.1}
        value={value}
        onChange={event => onChange(Number(event.target.value))}
      />
    </div>
  );
};
`}</code></pre>
    <p>{`HTML has built-in sliders so we don't have to reinvent the wheel. Render an
input, give it a `}<inlineCode parentName="p">{`type="range"`}</inlineCode>{`, set value from our prop, and parse the event
value in `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` before feeding it back to `}<inlineCode parentName="p">{`setRedRange`}</inlineCode>{` with our callback.`}</p>
    <p>{`Now each time you move that slider, it triggers a re-render, which generates a
new Mondrian from the data.`}</p>
    <p><a parentName="p" {...{
        "href": "https://mondrian-generator.swizec-react-dataviz.now.sh/"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/9czz3FL.gif",
          "alt": null
        }}></img></a></p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`In conclusion: generative art is hard, Piet Mondrian was brillianter than he
seems, and D3 treemaps are great fun.`}</p>
    <p>{`Hope you enjoyed this as much as I did :)`}</p>
    <p>{`Cheers,`}<br />{` ~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      